import React, { useState } from "react";

import { SearchBar } from "../../components/SearchBar/SearchBar";
import { TwitchUser } from "../../service/twitch/twitchService";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UserStatsController } from "../../components/Controller/UserStatsController";

export const WelcomePage = () => {
  const history = useHistory();
  const [user, setUser] = useState<TwitchUser>();

  return (
    <div>
      <div className="mx-auto max-w-6xl">
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="mt-24 text-2xl font-extrabold text-white md:max-w-4xl sm:text-3xl">
            What does your favorite <b className="text-purple-500">twitch</b>{" "}
            streamer actually earn? <br />
            find it out!
          </h1>
          <div className="pt-12">
            <SearchBar
              callback={(user) => {
                setUser(user);
                history.push(`/channel/${user.name}`);
              }}
              q={(useParams() as any).username}
            />
          </div>
          {user !== undefined ? (
            <div className="pt-12">
              <UserStatsController user={user} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
