import axios from "axios";

export interface IBookingService {
  getTreePositionForChannel(chanel: number): Promise<TreeResult>;
  getRanking(offset: number, limit: number): Promise<RankData[]>;
}

export interface TreeData {
  user_id: string;
  payout_entity_id: string;
  ad_share_gross: number;
  sub_share_gross: number;
  bits_share_gross: number;
  bits_developer_share_gross: number;
  bits_extension_share_gross: number;
  prime_sub_share_gross: number;
  bit_share_ad_gross: number;
  fuel_rev_gross: number;
  bb_rev_gross: number;
  report_date: string;
  experimental_rev_gross: number;
}
export interface RankData {
  rank: number | undefined;
  user_id: string;
  ad_share_gross: number;
  sub_share_gross: number;
  bits_share_gross: number;
  bits_developer_share_gross: number;
  bits_extension_share_gross: number;
  prime_sub_share_gross: number;
  bit_share_ad_gross: number;
  fuel_rev_gross: number;
  bb_rev_gross: number;
  experimental_rev_gross: number;
}

export interface TreeResult {
  count: number;
  data: TreeData[];
}

const backendApi = process.env.REACT_APP_BACKEND_API_URL || "";
const backendApiRanking = process.env.REACT_APP_BACKEND_API_URL_RANKING || "";

const bookingService = (): IBookingService => {
  return {
    async getTreePositionForChannel(channel: number): Promise<TreeResult> {
      return await axios
        .post(backendApi.replaceAll("%q%", channel.toString()), {
          "hcaptcha-response": "10000000-aaaa-bbbb-cccc-000000000001",
        })
        .then((response) => {
          return response.data as unknown as TreeResult;
        })
        .catch((error) => {
          throw error;
        });
    },
    async getRanking(offset: number, limit: number): Promise<RankData[]> {
      return await axios
        .get(
          backendApiRanking
            .replaceAll("%o%", offset.toString())
            .replaceAll("%l%", limit.toString())
        )
        .then((response) => {
          return response.data as unknown as RankData[];
        })
        .catch((error) => {
          throw error;
        });
    },
  };
};

export { bookingService };
