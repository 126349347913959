const faq = [
  {
    question: "Where do these data come from?",
    answear:
      "The data is part of the twitch data leak 2021 and shows the payouts of all twitch partners and affiliates.",
  },
  {
    question: 'What data is behind "total"?',
    answear:
      'Under the term total we summarize all existing records of the channel. Data from 08/2019 to 10/2021 are general available. However, a payout did not necessarily take place in each month. You can view all available data records in the "data table".',
  },
  {
    question: "Are all the data correct?",
    answear:
      "We have done our best to interpret all data sets correctly and present them here graphically and visually. It is beyond our responsibility whether the data from the leak match. But we have compared the data with several streamers and were able to verify the correctness.  Of course we do not guarantee completeness or correctness.",
  },
  {
    question: "Is this all income from twitch?",
    answear: `Yes this is all data from revenue through advertising as well as subs or prime subs. Also bits and ad bits are included. 
<br/><br/>
      Of course, many streamers also have additional revenues that are not part of this analysis, e.g. revenues from donations or external advertising deals. So you can be sure that the streamer has additional income.`,
  },
  {
    question: "Are there any contact options?",
    answear: `Of course, you are welcome to email us if you have any questions or problems about this website. We are also open to feedback or suggestions for improvement.
    <br/>
   E-Mail:  <b><a href="mailto:twitchleaks@pm.me">twitchleaks@pm.me</a></b>`,
  },
  {
    question: "Privacy?",
    answear: `Firstly, we take privacy very seriously. So we do not store any information from visitors to this website. However, when visiting you should know that data is sent to cloudflare and hCaptcha.. 
<br/><br/>
    Talking about privacy regarding the data leak, we want to clarify that we will never publish personal data. This data relates to the income of celebrities. Therefore, we do not see any conflict with data privacy. This data was not published by us, we only process the data that is freely available on the web and make it possible for even technically inexperienced people to quickly and easily take a look into these data sets.
   `,
  },
];

export { faq };
