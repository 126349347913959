import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { IChartYear } from "./IChart";
import React from "react";
import { formatter } from "./util/formatter";
import { getShortMonthByNumber } from "./util/getShortMonthByNumber";

export const ChartOfAdsByYears = ({ trees, year }: IChartYear) => {
  let data = trees.map((tree) => {
    const splittedTitle = tree.report_date.split("/");
    return {
      name: `${getShortMonthByNumber(Number(splittedTitle[0]))}`,
      ad: tree.ad_share_gross,
    };
  });

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <LineChart data={data} barCategoryGap={"10%"}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="1 1" />
        <Line type="monotone" name="Ads" dataKey="ad" stroke="#8d4bf3" />

        <Tooltip
          labelClassName="text-white"
          contentStyle={{
            backgroundColor: "#8d4bf3",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
          itemStyle={{
            color: "#fff",
          }}
          formatter={formatter}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
