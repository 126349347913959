/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React from "react";

export const Header = () => {
  return (
    <header className="sticky top-0 z-30 bg-opacity-50 h-[72px] bg-gray-900 backdrop-filter backdrop-blur  firefox:bg-opacity-90">
      <div className="max-w-8xl mx-auto xl:px-8">
        <div className="flex items-center justify-between px-4 py-5 border-b lg:px-8 sm:px-6 xl:px-0 border-gray-800">
          <Link to="/" className="block text-white font-bold text-xl">
            <b className="text-purple-400 text-2xl"> twitchleaks.me</b>
          </Link>
          <div>
            <Link to="/compare" className="text-white pr-12">
              Compare
            </Link>
            <Link to="/faq" className="text-white">
              FAQ
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};
