import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";
import { IChartCompare } from "../IChart";
import { formatter } from "../util/formatter";
import { getRandomColor, userColors } from "../util/randomColorCode";

export const TotalCharOfBitsCompare = ({
  users,
  stats,
  year,
}: IChartCompare) => {
  const data: any[] = [];
  let reportDates: string[] = [];
  let preparedData: any = {};

  Object.keys(stats).forEach(function (key, index) {
    const statsValue = stats[Number(key)];
    statsValue.forEach((stat) => {
      if (year) {
        if (stat.report_date.split("/")[2] === year.toString()) {
          return;
        }
      }
      if (!reportDates.includes(stat.report_date)) {
        reportDates.push(stat.report_date);
      }
    });
  });
  reportDates = reportDates.sort(
    (x, y) =>
      Number(x.split("/")[2] + x.split("/")[0]) -
      Number(y.split("/")[2] + x.split("/")[0])
  );
  reportDates.forEach((rd) => {
    Object.keys(stats).forEach(function (key, index) {
      const filterdStatsForReportDate = stats[Number(key)].filter(
        (x) => x.report_date === rd
      );
      preparedData[rd] = {
        name: rd,
        ...preparedData[rd],
        [`${key}_bits`]: filterdStatsForReportDate?.reduce(
          (x, y) =>
            x +
            y.bits_developer_share_gross +
            y.bits_extension_share_gross +
            y.bits_share_gross +
            y.bit_share_ad_gross,
          0
        ),
      };
    });
  });

  Object.keys(preparedData).forEach((key) => {
    data.push(preparedData[key]);
  });

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <LineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="1 1" />

        {users.map((u, i) => {
          return (
            <Line
              type="monotone"
              name={u.display_name}
              key={`line_${i}`}
              dataKey={`${u._id}_bits`}
              stroke={i > 2 ? getRandomColor() : userColors[i]}
            />
          );
        })}
        <Legend />
        <Tooltip
          labelClassName="text-white"
          contentStyle={{
            backgroundColor: "#8d4bf3",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
          itemStyle={{
            color: "#fff",
          }}
          formatter={formatter}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
