import React from "react";
import { TotalCard } from "../TotalCard";
import { TotalCharOfBits } from "../chars/TotalCharOfBits";
import { TotalChartEarningsByMonths } from "../chars/TotalChartEarningsByMonths";
import { TotalChartOfAds } from "../chars/TotalChartOfAds";
import { TreeTable } from "../TreeTable";
import { ITabProps } from "./ITabProps";

export const TotalTab = ({ user, trees }: ITabProps) => {
  return (
    <div>
      <br />
      <TotalCard trees={[...trees?.data!]} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Subscriptions (prime + normal)</h4>
      <TotalChartEarningsByMonths trees={trees} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Advertisements</h4>
      <TotalChartOfAds trees={trees} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Twitch Bits</h4>
      <TotalCharOfBits trees={trees} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Table of Data</h4>
      <TreeTable trees={trees?.data!} />
    </div>
  );
};
