/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { TwitchUser, twitchService } from "../../service/twitch/twitchService";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { CompareStatsController } from "../../components/Controller/CompareStatsController";
import { SearchBar } from "../../components/SearchBar/SearchBar";
import { UsersCards } from "../../components/UserStats/UsersCards";

export const ComparePage = () => {
  const [users, setUsers] = useState<any[]>([]);
  const usernamesRaw: { usernames?: string } = useParams();

  const history = useHistory();

  useEffect(() => {
    async function fetchMyAPI() {
      const us: TwitchUser[] = [];
      let usernames = usernamesRaw?.usernames
        ? usernamesRaw?.usernames.split(",")
        : [];

      usernames = usernames.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });

      await Promise.all(
        usernames.map(async (uname) => {
          await twitchService()
            .searchChannelsByName(uname)
            .then((res) => {
              const user = res?.users?.length > 0 ? res?.users[0] : undefined;
              if (user == undefined) return;
              us.push(user);
            });
        })
      ).then((x) => {
        setUsers(us);
      });
    }
    fetchMyAPI();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-6xl">
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="mt-24 text-2xl font-extrabold text-white md:max-w-4xl sm:text-3xl">
            Which <b className="text-purple-500">twitch</b> streamer earned
            more? <br />
            check it out
          </h1>
          <div className="pt-12">
            <SearchBar
              callback={(userNew) => {
                const us = users.filter((x) => x?._id !== userNew?._id);
                if (userNew === undefined) return;

                setUsers([...us, userNew]);
                history.push(
                  `/compare/${[...us, userNew]
                    .map((x) => x?.display_name)
                    .join(",")}`
                );
              }}
              q={""}
            />
            <UsersCards
              users={users}
              removeUser={(user) => {
                const us = users.filter((x) => x._id !== user._id);
                setUsers(us);
                history.push(
                  `/compare/${us.map((x) => x.display_name).join(",")}`
                );
              }}
            />
            {users.length > 1 ? (
              <>
                <div className="mainBody">
                  <CompareStatsController users={users} />
                </div>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-bold text-white">
                  Select two streamers to make a comparison ...
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
