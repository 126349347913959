import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { IChart } from "./IChart";
import React from "react";
import { formatter } from "./util/formatter";
import { getShortMonthByNumber } from "./util/getShortMonthByNumber";

export const TotalChartEarningsByMonths = ({ trees }: IChart) => {
  const data = trees?.data?.map((tree, i) => {
    const splittedTitle = tree.report_date.split("/");
    return {
      name: `${getShortMonthByNumber(Number(splittedTitle[0]))} ${
        splittedTitle[2]
      }`,
      subs: tree.sub_share_gross,
      prime_subs: tree.prime_sub_share_gross,
      subs_total: tree.sub_share_gross + tree.prime_sub_share_gross,
    };
  });
  var hash = Object.create(null);
  var result: any[] = [];

  trees?.data?.forEach(function (o) {
    const year = o.report_date.split("/")[2];
    if (!hash[year]) {
      hash[year] = {
        name: year,
        subs: o.sub_share_gross,
        prime_subs: o.prime_sub_share_gross,
        subs_total: o.sub_share_gross + o.prime_sub_share_gross,
      };
      result.push(hash[year]);
    }
    hash[year].subs += +o.sub_share_gross;
    hash[year].prime_subs += +o.prime_sub_share_gross;
    hash[year].subs_total += +o.sub_share_gross + +o.prime_sub_share_gross;
  });

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <LineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="1 1" />
        <Line type="monotone" name="Subs" dataKey="subs" stroke="#8d4bf3" />
        <Line
          type="monotone"
          name="PrimeSubs"
          dataKey="prime_subs"
          stroke="#F6ABB6"
        />
        <Line
          type="monotone"
          name="TotalSubs"
          dataKey="subs_total"
          stroke="#F6CD61"
        />
        <Legend />
        <Tooltip
          labelClassName="text-white"
          contentStyle={{
            backgroundColor: "#8d4bf3",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
          itemStyle={{
            color: "#fff",
          }}
          formatter={formatter}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
