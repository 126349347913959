/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { TreeData, bookingService } from "../../service/booking/bookingService";

import { ClipLoader } from "react-spinners";
import { TwitchUser } from "../../service/twitch/twitchService";
import lntrn from "../../util/largeNumberToReadableNumber";
import { YearTabController } from "../UserStats/YearTabController";
import { Tab } from "@headlessui/react";
import { TotalCompareTab } from "../UserStats/chars/compare/TotalCompareTab";

interface CompareStatsControllerProps {
  users: TwitchUser[];
}

export interface IStats {
  [key: number]: TreeData[];
}

export const CompareStatsController = ({
  users,
}: CompareStatsControllerProps) => {
  const [state, setState] = useState<IStats>({});
  const [pending, setPending] = useState(false);
  const [topSortedUsers, setTopSortedUsers] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      let ts: IStats = {};
      await Promise.all(
        users.map(async (user) => {
          await bookingService()
            .getTreePositionForChannel(user._id)
            .then((stats) => {
              ts[Number(user._id)] = stats.data;
            })
            .catch((e) => {
              alert("No stats found for " + user.display_name);
            });
        })
      ).then((x) => {
        setState(ts);
        getUserSortedByTotal(ts);
        setPending(false);
      });
    }

    fetchData();
  }, [users]);

  const getUserSortedByTotal = (stats: IStats) => {
    let totalEarned: any = {};
    var sortable = [];

    Object.keys(stats).forEach((key: string, index) => {
      totalEarned[key] = 0;
      stats[Number(key)]?.forEach((s) => {
        totalEarned[key] +=
          s.ad_share_gross +
          s.bb_rev_gross +
          s.bit_share_ad_gross +
          s.bits_developer_share_gross +
          s.bits_extension_share_gross +
          s.bits_share_gross +
          s.experimental_rev_gross +
          s.fuel_rev_gross +
          s.prime_sub_share_gross +
          s.sub_share_gross;
      });
    });
    for (var userId in totalEarned) {
      sortable.push([Number(userId), totalEarned[userId]]);
    }
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    setTopSortedUsers(sortable);
  };

  if (pending) return <ClipLoader loading={true} size={40} color={"#8d4bf3"} />;

  if (topSortedUsers.length > 0) {
    let topUsers = users.find((u) => u._id == topSortedUsers[0][0]);
    return (
      <div className=" text-white pt-">
        <div className="flex flex-col md:flex-row sm:flex-row  items-center justify-start px-4 py-5 border-t lg:px-8 sm:px-6 xl:px-0 border-gray-800">
          <img
            className="rounded-lg"
            width="160px"
            src={topUsers?.logo}
            alt={topUsers?.display_name}
          />

          <div className="header-block pl-0 lg:pl-20 md:pl-3 pt-12 lg:pt-0">
            <h2 className="text-4xl font-bold ">
              Sorry{" "}
              <b className="text-purple-500">
                {topSortedUsers.length > 1
                  ? users.find((user) => user._id == topSortedUsers[1][0])
                      ?.display_name
                  : " Nobody"}
              </b>{" "}
              but I earn{" "}
              <b className="text-purple-500">
                ${` ${lntrn(topSortedUsers[0][1] - topSortedUsers[1][1])}`}
              </b>{" "}
              <br /> more than you 😂
            </h2>
          </div>
        </div>
        <YearTabController>
          <Tab.Panels className="pt-8 pl-4">
            <Tab.Panel>
              <TotalCompareTab stats={state} users={users} />
            </Tab.Panel>
            <Tab.Panel>
              <TotalCompareTab users={users} stats={state} year={2019} />
            </Tab.Panel>
            <Tab.Panel>
              <TotalCompareTab users={users} stats={state} year={2020} />
            </Tab.Panel>
            <Tab.Panel>
              <TotalCompareTab users={users} stats={state} year={2021} />
            </Tab.Panel>
          </Tab.Panels>
        </YearTabController>
      </div>
    );
  }

  return <ClipLoader loading={true} size={40} color={"#8d4bf3"} />;
};
