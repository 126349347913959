function lntrn(amount: number) {
  var str = Math.floor(amount) + ""; //make the integer a string
  var sub = str.substring(str.length - 3, str.length); //the last three characters
  let newstr = " " + sub;
  var i = 1;
  while (sub.length >= 3) {
    sub = str.substring(str.length - (i + 1) * 3, str.length - i * 3); //next three characters
    if (sub === "") {
      newstr = sub + " " + newstr;
    } else {
      newstr = sub + " " + newstr; //append the characters
    }

    i += 1;
  }
  return newstr;
}

export default lntrn;
