import React from "react";
import { ITabProps } from "../../Tabs/ITabProps";
import { DataTableCompare } from "./DataTableCompare";
import { TotalCharOfBitsCompare } from "./TotalCharOfBitsCompare";
import { TotalChartOfAdsCompare } from "./TotalChartOfAdsCompare";
import { TotalChartSubsCompare } from "./TotalChartSubsCompare";

export const TotalCompareTab = ({ users, stats, year }: ITabProps) => {
  return (
    <div>
      <br />
      <br />
      <h4 className="font-bold text-xl p-4 ">Subscriptions (prime + normal)</h4>
      <TotalChartSubsCompare stats={stats!} users={users!} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Advertisements</h4>
      <TotalChartOfAdsCompare stats={stats!} users={users!} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Twitch Bits</h4>
      <TotalCharOfBitsCompare stats={stats!} users={users!} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Table of Data</h4>
      <DataTableCompare users={users!} stats={stats!} year={year} />
    </div>
  );
};
