import { ChartEarningsByYears } from "../chars/CharEarningsByYears";
import { ChartOfAdsByYears } from "../chars/ChartOfAdsByYears";
import { ChartOfBitsByYear } from "../chars/ChartOfBitsByYear";
import { TotalCard } from "../TotalCard";
import { TreeData } from "../../../service/booking/bookingService";
import { TreeTable } from "../TreeTable";
import { ITabYearProps } from "./ITabProps";

export const YearTab = ({ user, trees, year }: ITabYearProps) => {
  let treesByYear: TreeData[] = [];
  trees?.data?.forEach((tree, i) => {
    if (tree.report_date.includes(year.toString())) treesByYear.push(tree);
  });

  return (
    <div>
      <br />
      <TotalCard trees={treesByYear} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Subscriptions (prime + normal)</h4>
      <ChartEarningsByYears trees={treesByYear} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Advertisements</h4>
      <ChartOfAdsByYears trees={treesByYear} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Twitch Bits</h4>
      <ChartOfBitsByYear trees={treesByYear} year={year} />
      <br />
      <h4 className="font-bold text-xl p-4 ">Table of Data</h4>
      <TreeTable trees={treesByYear} />
    </div>
  );
};
