import { Tab } from "@headlessui/react";

export interface YearTabControllerProps {
  children?: React.ReactNode;
}

export interface YearTabControllerPropsExtended extends YearTabControllerProps {
  year: number;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const YearTabController = ({ children }: YearTabControllerProps) => {
  const steps = [
    {
      title: "Total",
      year: 0,
    },
    {
      title: "2019",
      year: 2019,
    },
    {
      title: "2020",
      year: 2020,
    },
    {
      title: "2021",
      year: 2021,
    },
  ];

  return (
    <div className="w-full  px-2 py-16 sm:px-0 ">
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl border border-white">
          {steps.map((step, i) => (
            <Tab
              key={step.year}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium text-purple-500 rounded-lg",

                  selected
                    ? "bg-white shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {step.title}
            </Tab>
          ))}
        </Tab.List>
        {children}
      </Tab.Group>
    </div>
  );
};
