import React, { useEffect, useState } from "react";
import {
  TreeData,
  TreeResult,
  bookingService,
} from "../../service/booking/bookingService";

import { ClipLoader } from "react-spinners";
import { TwitchUser } from "../../service/twitch/twitchService";
import { YearTabController } from "../UserStats/YearTabController";
import lntrn from "../../util/largeNumberToReadableNumber";
import { Tab } from "@headlessui/react";
import { YearTab } from "../UserStats/Tabs/YearTab";
import { TotalTab } from "../UserStats/Tabs/TotalTab";

interface UserStatsControllerProps {
  user: TwitchUser;
  children?: React.ReactNode;
}
export const UserStatsController = ({ user }: UserStatsControllerProps) => {
  const [trees, setTrees] = useState<TreeResult | undefined>(undefined);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    bookingService()
      .getTreePositionForChannel(user._id)
      .then((tree) => {
        const treesSorted = tree?.data?.sort((a: TreeData, b: TreeData) => {
          const aDate = a.report_date.split("/");
          const bDate = b.report_date.split("/");
          return Number(aDate[2] + aDate[0]) - Number(bDate[2] + bDate[0]);
        });

        setTrees({
          count: tree.count,
          data: treesSorted,
        });
        setPending(false);
      })
      .catch(() => {
        setTrees(undefined);
        alert("Error loading data");
      });
  }, [user]);

  let treesByYear: TreeData[] = [];
  trees?.data?.forEach((tree, i) => {
    if (tree.report_date.includes("2020")) treesByYear.push(tree);
  });

  let totalEarned = treesByYear.reduce(function (acc, obj) {
    return (
      acc +
      obj.ad_share_gross +
      obj.bb_rev_gross +
      obj.bit_share_ad_gross +
      obj.bits_developer_share_gross +
      obj.bits_extension_share_gross +
      obj.bits_share_gross +
      obj.experimental_rev_gross +
      obj.fuel_rev_gross +
      obj.prime_sub_share_gross +
      obj.sub_share_gross
    );
  }, 0);

  if (pending) return <ClipLoader loading={true} size={40} color={"#8d4bf3"} />;

  return (
    <div className=" text-white">
      <div className="flex flex-col md:flex-row sm:flex-row  items-center justify-start px-4 py-5 border-t lg:px-8 sm:px-6 xl:px-0 border-gray-800">
        <img
          className="rounded-lg"
          width="160px"
          src={user.logo}
          alt={user.display_name}
        />
        <div className="header-block pl-0 lg:pl-20 md:pl-3 pt-12 lg:pt-0">
          <h2 className="text-4xl font-bold ">
            Hello 👋, I am{" "}
            <b className="text-purple-500">{user.display_name}</b>
          </h2>
          <h3 className="text-2xl pt-2">
            last year I made around{" "}
            <b className="text-purple-500 inline-block">{` $ ${lntrn(
              totalEarned
            )}`}</b>{" "}
            with twitch.
          </h3>
        </div>
      </div>
      <YearTabController>
        <Tab.Panels className="pt-8 pl-4">
          <Tab.Panel>
            <TotalTab user={user} trees={trees} />
          </Tab.Panel>
          <Tab.Panel>
            <YearTab user={user} trees={trees} year={2019} />
          </Tab.Panel>
          <Tab.Panel>
            <YearTab user={user} trees={trees} year={2020} />
          </Tab.Panel>
          <Tab.Panel>
            <YearTab user={user} trees={trees} year={2021} />
          </Tab.Panel>
        </Tab.Panels>
      </YearTabController>
    </div>
  );
};
