import axios from "axios";

export interface ITwitchService {
  searchChannelsByName(name: string): Promise<TwitchNameResult>;
}

export interface TwitchUser {
  display_name: string;
  _id: number;
  name: string;
  type: string;
  bio?: string;
  created_at: Date;
  updated_at: Date;
  logo: string;
}

export interface TwitchNameResult {
  _total: number;
  users: TwitchUser[];
}

export interface TwitchTokenGeneratorResponse {
  success: boolean;
  token: string;
  refresh: string;
  client_id: string;
}

const twitchUrl =
  process.env.REACT_APP_TWITCH_API_URL || "https://gql.twitch.tv/gql";

const twitchClientId =
  process.env.REACT_APP_TWITCH_CLIENT_ID || "kimne78kx3ncx6brgo4mv6wki5h1ko";

const twitchService = (): ITwitchService => {
  return {
    async searchChannelsByName(name: string): Promise<TwitchNameResult> {
      const requestBody = [
        {
          operationName: "SearchResultsPage_SearchResults",
          variables: {
            query: name,
            options: null,
          },
          extensions: {
            persistedQuery: {
              version: 1,
              sha256Hash:
                "ee977ac21b324669b4c109be49ed3032227e8850bea18503d0ced68e8156c2a5",
            },
          },
        },
      ];

      return await axios
        .post(twitchUrl, requestBody, {
          headers: {
            "Client-ID": twitchClientId,
          },
        })
        .then((response: any) => {
          const users = response?.data[0].data.searchFor.channels.edges.map(
            (edge: any) => {
              return {
                _id: edge.item.id,
                name: edge.item.displayName,
                logo: edge.item.profileImageURL,
                bio: edge.item.description,
                type: "channel",
                display_name: edge.item.displayName,
              } as TwitchUser;
            }
          );

          return {
            _total: response?.data[0].data.searchFor.channels.edges.length,
            users,
          } as TwitchNameResult;
        })
        .catch((error) => {
          throw error;
        });
    },
  };
};

export { twitchService };
