import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ComparePage } from "./view/ComparePage/ComparePage";
import { FAQPage } from "./view/FAQPage/FAQPage";
import { Header } from "./layout/Header";
import { WelcomePage } from "./view/WelcomePage/WelcomePage";

//dotenv config
require("dotenv").config();

function App() {
  //alert("🤖 Twitch has disabled api v5, so this website is not working at the moment. We are already working on a solution.")
  return (
    <BrowserRouter>
      <div className="body bg-rex-500 h-screen">
        <Header />
        <div className="w-full bg-rex-500">
          <Switch>
            <Route path="/faq" children={<FAQPage />} exact />

            <Route path="/channel/:username" component={WelcomePage} exact />
            <Route path="/compare/:usernames" component={ComparePage} exact />
            <Route path="/compare" component={ComparePage} exact />
            <Route path="/" component={WelcomePage} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
