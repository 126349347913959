import "react-dropdown/style.css";

import React, { useEffect, useState } from "react";
import {
  TwitchNameResult,
  TwitchUser,
  twitchService,
} from "../../service/twitch/twitchService";

import { Listbox } from "@headlessui/react";
import language from "../../language/german";

interface SearchBarProps {
  callback: (user: TwitchUser) => void;
  q?: string;
}

export const SearchBar = (props: SearchBarProps) => {
  const [results, setResults] = useState<TwitchNameResult | undefined>(
    undefined
  );
  const [input, setInput] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);

  const inputChange = async (textInput: string) => {
    setInput(textInput);
  };

  const preCallback = (user: TwitchUser) => {
    props.callback(user);
  };
  /**
   * Q params automatic get stats of user param
   */
  useEffect(() => {
    navigaetToUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigaetToUser = async () => {
    if (props.q === undefined) return;
    setPending(true);
    const twitchResult = await twitchService().searchChannelsByName(props.q);
    if (twitchResult) {
      setResults(twitchResult);
      setPending(false);
      if (twitchResult?.users !== undefined)
        preCallback(twitchResult?.users[0]);
    }
  };

  const callApi = async () => {
    setPending(true);
    const twitchResult = await twitchService().searchChannelsByName(input);
    if (twitchResult) {
      setResults(twitchResult);
      setPending(false);
    }
  };

  useEffect(() => {
    if (input.length < 3) {
      setResults(undefined);
      return;
    }
    setResults(undefined);
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const renderNoResults = (
    <Listbox.Option
      key={"notfound"}
      value={"notfound"}
      disabled={false}
      className="py-1"
    >
      <div className="flex ">
        <p className="text-white"> {language.SEARCH_EMPTY}</p>
      </div>
    </Listbox.Option>
  );

  return (
    <div>
      <div className="flex relative ">
        <input
          type="text"
          id="usernameSearch"
          className=" rounded-l-lg flex-1  border border-purple-500 w-full py-2 px-4 bg-rex-500 text-white placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
          name="email"
          placeholder={language.SEARCH_PLACEHOLDER}
          onChange={(e) => inputChange(e.target.value)}
          value={input}
          autoComplete="off"
          onKeyPress={async (event) => {
            if (event.key === "Enter") {
              setPending(true);

              const twitchResult = await twitchService().searchChannelsByName(
                input
              );
              if (twitchResult) {
                setResults(twitchResult);
                setPending(false);
                if (twitchResult?.users !== undefined)
                  if (results?.users[0]?._id !== undefined) {
                    preCallback(twitchResult?.users[0]);
                    inputChange("");
                  }
              }
            }
          }}
        />
        <span
          id="channelSearchButton"
          onClick={() => {
            if (results?.users !== undefined) {
              if (results?.users[0]?._id !== undefined) {
                preCallback(results?.users[0]);
                inputChange("");
              }
            }
          }}
          className="rounded-r-md inline-flex  items-center px-3 border-t bg-purple-500 border-r border-b  border-purple-500 text-white shadow-sm text-sm"
        >
          <svg
            width="20px"
            height="20px"
            version="1.1"
            viewBox="0 0 20 20"
            x="0px"
            y="0px"
            className="text-white"
          >
            <g>
              <path
                fillRule="evenodd"
                d="M13.192 14.606a7 7 0 111.414-1.414l3.101 3.1-1.414 1.415-3.1-3.1zM14 9A5 5 0 114 9a5 5 0 0110 0z"
                clipRule="evenodd"
              ></path>
            </g>
          </svg>
        </span>
      </div>
      {input !== "" ? (
        <div
          id="usernameSuggestions"
          className="mt-1 rounded-b-lg  border-l border-r border-b border-gray-800 w-full py-2 px-4 bg-rex-500 text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-purple-600 focus:border-transparent"
        >
          <Listbox value={results} onChange={() => {}}>
            <Listbox.Options static>
              {results?.users?.map((user, i) => (
                <div key={i}>
                  <button
                    onClick={() => {
                      preCallback(user);
                      inputChange("");
                    }}
                  >
                    <Listbox.Option
                      key={user._id}
                      value={user._id}
                      disabled={false}
                      className="py-1"
                    >
                      <div className="flex ">
                        <img
                          width="32px"
                          className="rounded-full mr-2"
                          src={user?.logo}
                          alt="logo"
                        />
                        <p className="text-white"> {user.display_name}</p>
                      </div>
                    </Listbox.Option>
                  </button>
                </div>
              ))}
              {results?._total === 0 || results === undefined || pending
                ? renderNoResults
                : null}
            </Listbox.Options>
          </Listbox>
        </div>
      ) : null}
    </div>
  );
};
