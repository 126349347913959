import React from "react";
import { TwitchUser } from "../../../../service/twitch/twitchService";
import lntrn from "../../../../util/largeNumberToReadableNumber";
import { IStats } from "../../../Controller/CompareStatsController";

export interface DataTableCompareProps {
  users: TwitchUser[];
  stats: IStats;
  year?: number;
}

export const DataTableCompare = ({
  users,
  stats,
  year,
}: DataTableCompareProps) => {
  if (users === undefined) return <div>No users selected</div>;

  return (
    <div className="flex flex-col pb-64">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden  sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                  >
                    Channel
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Twitch Subs
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Prime Subs
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ads
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bits
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bits (advertisements)
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bits (developer)
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bits (extension)
                  </th>
                </tr>
              </thead>
              <tbody className="text-white divide-y divide-gray-600">
                {users?.map((user, i) => {
                  let userStats = stats[user._id];
                  if (year)
                    userStats = userStats?.filter(
                      (x) => x.report_date.split("/")[2] === year?.toString()
                    );

                  return (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-400">
                              {user?.display_name}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          $
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.sub_share_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.prime_sub_share_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce((x, y) => x + y.ad_share_gross, 0)
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.bits_share_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.bit_share_ad_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.bits_developer_share_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="text-sm text-gray-500">
                          ${" "}
                          {lntrn(
                            userStats?.reduce(
                              (x, y) => x + y.bits_extension_share_gross,
                              0
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
