import React from "react";
import { TreeData } from "../../service/booking/bookingService";
import lntrn from "../../util/largeNumberToReadableNumber";

export interface TotalCardProps {
  trees: TreeData[];
}

export const TotalCard = (props: TotalCardProps) => {
  return (
    <div className="py-6">
      <div className="flex flex-wrap -mx-1 overflow-hidden">
        <div className="my-1 px-1 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4">
          <div className="flex flex-rows bg-gray-300 h-12 rounded-md w-full">
            <b className="text-purple-500 px-4 py-3 w-1/2">Total</b>
            <p
              id="total-card-1"
              className="bg-purple-500 px-4 py-3 w-1/2 text-white rounded-r-md"
            >
              ${" "}
              {lntrn(
                props.trees.reduce(function (acc, obj) {
                  return (
                    acc +
                    obj.ad_share_gross +
                    obj.bb_rev_gross +
                    obj.bit_share_ad_gross +
                    obj.bits_developer_share_gross +
                    obj.bits_extension_share_gross +
                    obj.bits_share_gross +
                    obj.experimental_rev_gross +
                    obj.fuel_rev_gross +
                    obj.prime_sub_share_gross +
                    obj.sub_share_gross
                  );
                }, 0.0)
              )}
            </p>
          </div>
        </div>
        <div className="my-1 px-1 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4">
          <div className="flex flex-rows bg-gray-300 h-12 rounded-md w-full">
            <b className="text-purple-500 px-4 py-3 w-1/2">Twitch Subs</b>
            <p
              id="total-card-2"
              className="bg-purple-500 px-4 py-3 w-1/2 text-white rounded-r-md"
            >
              ${" "}
              {lntrn(
                props.trees.reduce(function (acc, obj) {
                  return acc + obj.sub_share_gross + obj.prime_sub_share_gross;
                }, 0.0)
              )}
            </p>
          </div>
        </div>

        <div className="my-1 px-1 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4">
          <div className="flex flex-rows bg-gray-300 h-12 rounded-md w-full">
            <b className="text-purple-500 px-4 py-3 w-1/2">Ads</b>
            <p
              id="total-card-3"
              className="bg-purple-500 px-4 py-3 w-1/2 text-white rounded-r-md"
            >
              ${" "}
              {lntrn(
                props.trees.reduce(function (acc, obj) {
                  return acc + obj.ad_share_gross;
                }, 0.0)
              )}
            </p>
          </div>
        </div>

        <div className="my-1 px-1 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4">
          <div className="flex flex-rows bg-gray-300 h-12 rounded-md w-full">
            <b className="text-purple-500 px-4 py-3 w-1/2">Bits</b>
            <p
              id="total-card-4"
              className="bg-purple-500 px-4 py-3 w-1/2 text-white rounded-r-md"
            >
              ${" "}
              {lntrn(
                props.trees.reduce(function (acc, obj) {
                  return acc + obj.bits_share_gross + obj.bit_share_ad_gross;
                }, 0.0)
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
