import React from "react";
import { TwitchUser } from "../../service/twitch/twitchService";

export interface UsersCardProps {
  users: TwitchUser[];
  removeUser: (user: TwitchUser) => void;
}

export const UsersCards = (props: UsersCardProps) => {
  return (
    <div className="py-6">
      <div className="flex flex-wrap -mx-1 overflow-hidden">
        {props.users.map((user: TwitchUser, i) => {
          return (
            <div
              className="my-1 px-1 w-full overflow-hidden md:w-1/2 lg:w-1/4 xl:w-1/4 "
              key={i}
            >
              <div className="flex flex-rows bg-purple-500 h-24 rounded-md">
                <b className="text-purple-500 w-48">
                  <img
                    alt="twitch-avatar"
                    className="rounded-l-md h-full"
                    src={user?.logo}
                  />
                </b>
                <p className=" text-center w-full py-8  text-white rounded-r-md">
                  {user?.display_name}
                </p>
                <span
                  className="text-lg pr-2 text-white font-bold hover:text-black cursor-pointer"
                  onClick={() => props.removeUser(user)}
                >
                  x
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
