import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { IChartYear } from "./IChart";
import React from "react";
import { formatter } from "./util/formatter";
import { getShortMonthByNumber } from "./util/getShortMonthByNumber";

export const ChartEarningsByYears = ({ trees, year }: IChartYear) => {
  let data = trees.map((tree) => {
    const splittedTitle = tree.report_date.split("/");
    return {
      name: `${getShortMonthByNumber(Number(splittedTitle[0]))}`,
      subs: tree.sub_share_gross,
      prime_subs: tree.prime_sub_share_gross,
      subs_total: tree.sub_share_gross + tree.prime_sub_share_gross,
    };
  });

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <LineChart data={data} barCategoryGap={"10%"}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="1 1" />
        <Line type="monotone" name="Subs" dataKey="subs" stroke="#8d4bf3" />
        <Line
          type="monotone"
          name="PrimeSubs"
          dataKey="prime_subs"
          stroke="#F6ABB6"
        />
        <Line
          type="monotone"
          name="TotalSubs"
          dataKey="subs_total"
          stroke="#F6CD61"
        />
        <Legend />
        <Tooltip
          labelClassName="text-white"
          contentStyle={{
            backgroundColor: "#8d4bf3",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
          itemStyle={{
            color: "#fff",
          }}
          formatter={formatter}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
