import { Disclosure } from "@headlessui/react";
import Parser from "html-react-parser";
import React from "react";
import { faq } from "../../data/faq";
export const FAQPage = () => {
  return (
    <div>
      <div className="mx-auto max-w-6xl">
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="mt-24 text-2xl font-extrabold text-white md:max-w-4xl sm:text-3xl">
            <b className="text-purple-500">FAQ</b> about this page
          </h1>
          <div className="w-full px-4 pt-16">
            <div className="w-full  mx-auto rounded-2xl">
              {faq.map((q, i) => {
                return (
                  <div className="pt-5">
                    <Disclosure key={i}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span>{q.question}</span>
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            {Parser(q.answear)}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
