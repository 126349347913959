import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { IChartYear } from "./IChart";
import React from "react";
import { formatter } from "./util/formatter";
import { getShortMonthByNumber } from "./util/getShortMonthByNumber";

export const ChartOfBitsByYear = ({ trees, year }: IChartYear) => {
  let data = trees.map((tree) => {
    const splittedTitle = tree.report_date.split("/");
    return {
      name: `${getShortMonthByNumber(Number(splittedTitle[0]))}`,
      bits: tree.bits_share_gross,
      bits_ad: tree.bit_share_ad_gross,
    };
  });

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <LineChart data={data} barCategoryGap={"10%"}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="1 1" />
        <Line type="monotone" name="Bits" dataKey="bits" stroke="#F6CD61" />
        <Line
          type="monotone"
          name="Bits (advertisements)"
          dataKey="bits_ad"
          stroke="#F6ABB6"
        />

        <Legend />
        <Tooltip
          labelClassName="text-white"
          contentStyle={{
            backgroundColor: "#8d4bf3",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
          itemStyle={{
            color: "#fff",
          }}
          formatter={formatter}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
